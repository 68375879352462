import { FC } from 'react';
import Link from 'next/link';
import { parseISO, format } from 'date-fns';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { getAuthorFullName, hideImgOnError } from '@/helpers/helpers';
import BookCardRating from '../book-card-rating/BookCardRating';
import { getTruncatedTxt } from '@/helpers/helpers';
import { IBookCardItem } from '@/interfaces/bookCardItem.interface';
import styles from './BookCardItem.module.scss';

const BookCardItem: FC<IBookCardItem> = ({ extraClassName, book, author }) => {
  const bookAlias = `/avtory/${author.alias}/${book.alias}/`;

  return (
    <div className={extraClassName ? `${styles.bookCardItem} ${styles[`${extraClassName}`]}` : `${styles.bookCardItem}`}>
      <div className={styles.bookCardItem__img}>
        <Link prefetch={false} href={bookAlias}>
          {book.allow_full_free && book.allow_full_free > 0 ? <span className={styles.bookCardItem__fullVersion}>Полная версия</span> : <></>}
          <img src={`${DEV_ABSOLUTE_URL}/covers_185/${book.external_id}.jpg`} alt={book.title} width="123" height="175" onError={hideImgOnError} />
        </Link>
      </div>
      <div className={styles.bookCardItem__desc}>
        {author.first_name!?.length > 1 && (
          <div className={styles.bookCardItem__author}>
            <Link prefetch={false} href={`/avtory/${author.alias}/`}>
              {getAuthorFullName(String(author.first_name), String(author.middle_name), String(author.last_name))}
            </Link>
          </div>
        )}
        <div className={styles.bookCardItem__name}>
          <Link prefetch={false} href={bookAlias}>
            {book.title}
          </Link>
        </div>
        <div className={styles.bookCardItem__type}>{book.type === 'book' ? 'электронная книга' : 'аудиокнига'}</div>
        {book.created_at && (
          <div className={styles.bookCardItem__date}>
            <time dateTime={book.created_at}>{format(parseISO(book.created_at), 'dd.MM.yyyy')}</time>
          </div>
        )}
        <div className={styles.bookCardItem__rating}>
          <BookCardRating rating={book.rating} />
        </div>
        {book.year && <div className={styles.bookCardItem__year}>Год написания книги {book.year}</div>}
        {book.genre && <div className={styles.bookCardItem__genre}>Жанр: {book.genre}</div>}
        <div className={styles.bookCardItem__text}>{getTruncatedTxt(book.description, 195)}</div>
      </div>
    </div>
  );
};

export default BookCardItem;
