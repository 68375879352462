export const oldSlider = (ref) => {
  /* [37228] Стилизованный кроссбраузерный скроллбар */
  function scrollbar(node, settings) {
    var self = this;
    self.node = node;
    self.config = {
      width: false, // width scrollbar
      wrapper: 'scrollwrapper',
      scroller: 'scroller',
      scrollarea: 'scrollarea',
      scrollareaY: 'scrollareaY',
      scrollareaX: 'scrollareaX',
      content: 'scrollbar',
      show: false,
      scrollbar_parent: 'scrollbar_parent',
      scrollPage: false,
      navBar: false, // navigation left right
      showPingEffect: false, // показывать ли эффект отскока в крайних положениях,
      useAdvancedTouch: false, // использовать новый код для тач-устройств
      removeNavBarIfPossible: false, // убирать ли весь navBar, если контент и так влезает
      isDebug: false,
      fullWidth: false,
      onlyVerticalSwiping: false,
      isLooped: false, // после последнего слайда будет показывать первый, и наоборот
      dragBeginTimeout: 300, // через сколько мс зажима слайдера начинать драггинг курсором, 0 - без драггинга
      itemsToShift: 3, // сколько элементов сдвигать при нажатии кнопок "влево"/"право", 0 - сдвигать по умолчанию
      anchorItems: true, // приклеивать ли элементы к левому краю
      anchorItemsOffset: {
        // отступы от левого края, пиксели (в левом и среднем положении)
        begin: 5,
        center: 5,
      },
      enableTouchpadScrollX: true,
      autoScrollInSeconds: 0, // время, через которое автоматически обновлять слайд (в секундах)
      markersWrapper: undefined, // обёртчик, в который будут добавлены маркеры (по умолчанию в self.wrapper),
      sinusMovementOptions: {
        enabled: false,
        speed: 55,
        arg1: 0.6,
        arg2: 0.39,
      },
      enabledTouchAxes: {
        // на свайп по каким осям реагировать
        x: true,
        y: true,
      },
      touchBehaviour: 'scroll', // 'scroll' позволяет скроллить на промежуточные позиции, 'swipe' скроллит на целый блок
    };
    self.extend = function (a, b) {
      for (var key in b) if (b.hasOwnProperty(key)) a[key] = b[key];
      return a;
    };
    self.classList = (function () {
      if ('classList' in document.createElement('div')) {
        return {
          add: function (el, el_class) {
            el.classList.add(el_class);
          },
          remove: function (el, el_class) {
            el.classList.remove(el_class);
          },
        };
      } else {
        return {
          add: function (el, el_class) {
            el.className += ' ' + el_class;
          },
          remove: function (el, el_class) {
            el.className = (' ' + el.className + ' ').replace(' ' + el_class + ' ', ' ');
          },
        };
      }
    })();
    self.addEvent = function (el, e, f) {
      if (el.attachEvent) {
        return el.attachEvent('on' + e, f);
      } else {
        return el.addEventListener(e, f, false);
      }
    };
    self.removeEvent = function (el, e, f) {
      if (el.detachEvent) {
        return el.detachEvent('on' + e, f);
      } else {
        return el.removeEventListener(e, f, false);
      }
    };
    self.config = self.extend(self.config, settings);
    self.init();
  }

  scrollbar.prototype = {
    slide: 1,
    slides: null,
    wrapper: false, // обертка для скролла
    scrollareaY: false, // область вертикального скролла
    scrollareaX: false, // область горизонтального скролла
    scrollerY: false, // бегунок вертикального скролла
    scrollerX: false, // бегунок горизонтального скролла
    isLeftBorder: true, // бегунок находится в самом левом положении
    isRightBorder: false, // бегунок находится в самом правом положении
    init: function () {
      var self = this;

      if (self.config.fullWidth) {
        self.slides = self.node.childNodes.length;
      }
      self.render();
      self.refresh();

      /* init events */
      (self.canDrag = false), (self.scrollshow = false), (self.scrollHover = false);
      self.isMouseDown = false;
      self.navBarIsVisible = true;

      self.node.onmouseover = function (event) {
        self.refresh();
      };
      self.addEvent(self.scrollerY, 'mousedown', function (event) {
        self.dragY(event);
      });
      self.addEvent(self.scrollerX, 'mousedown', function (event) {
        self.dragX(event);
      });
      self.addEvent(window, 'mousemove', function (event) {
        self.moveY(event);
      });
      self.addEvent(window, 'mousemove', function (event) {
        self.moveX(event);
      });
      self.addEvent(window, 'mouseup', function (event) {
        self.drop(event);
      });

      this.previousTouch = { x: undefined, y: undefined };
      if (self.config.touchBehaviour === 'scroll') {
        self.addEvent(self.node, 'touchstart', self.scrollTouchStart.bind(self));
        self.addEvent(self.node, 'touchmove', self.scrollTouchMove.bind(self));
      } else if (self.config.touchBehaviour === 'vertical-swipe') {
        self.addEvent(self.node, 'touchstart', self.swipeTouchStart.bind(self));
        self.addEvent(self.node, 'touchmove', self.swipeTouchMove.bind(self));
        self.addEvent(self.node, 'touchend', self.swipeTouchEnd.bind(self));
      }

      if (self.config.dragBeginTimeout) {
        // отключаем возможность "дергать" ссылки и картинки внутри враппера
        var wrapperImgs = self.wrapper.querySelectorAll('img');
        var wrapperLinks = self.wrapper.querySelectorAll('a');
        var i = undefined;
        for (i = 0; i < wrapperImgs.length; i++) {
          wrapperImgs[i].setAttribute('draggable', 'false');
          self.addEvent(wrapperImgs[i], 'mousedown', function (e) {
            e.preventDefault();
          });
        }
        for (i = 0; i < wrapperLinks.length; i++) {
          wrapperLinks[i].setAttribute('draggable', 'false');
        }
        self.wrapper.classList.add('no-select');

        var scrollbarParent = self.wrapper.querySelector('.scrollbar_parent');
        if (scrollbarParent) {
          self.addEvent(scrollbarParent, 'mousedown', self.onDragStartHandler.bind(self));
          self.addEvent(window, 'mouseup', self.onDragEndHandler.bind(self));
        }
      }

      /* mouse wheel */
      if (!self.config.scrollPage) {
        if (self.contentWrapper.addEventListener)
          // ff
          self.contentWrapper.addEventListener(
            'DOMMouseScroll',
            function (event) {
              self.wheel(event);
            },
            false
          );
        // other
        self.contentWrapper.onmousewheel = function (event) {
          self.wheel(event);
        };
      }

      self.addEvent(self.scrollareaY, 'mousedown', function (event) {
        self.areaclickY(event);
      });
      self.addEvent(self.scrollareaX, 'mousedown', function (event) {
        self.areaclickX(event);
      });

      self.addEvent(self.scrollareaX, 'touchstart', function (event) {
        self.areatouchX(event);
      });
      self.addEvent(self.scrollareaX, 'touchmove', function (event) {
        self.areatouchX(event);
      });

      // fadein fadeout
      if (!self.config.show) {
        self.addEvent(self.scrollareaY, 'mouseover', function (event) {
          self.fadeIn(true);
        });
        self.addEvent(self.scrollareaX, 'mouseover', function (event) {
          self.fadeIn(true);
        });
        self.addEvent(self.scrollerY, 'mouseover', function () {
          self.fadeIn(true);
        });
        self.addEvent(self.scrollerX, 'mouseover', function () {
          self.fadeIn(true);
        });
        self.addEvent(self.scrollareaY, 'mouseout', function () {
          self.ScrollFade(true);
        });
        self.addEvent(self.scrollareaX, 'mouseout', function () {
          self.ScrollFade(true);
        });
        self.addEvent(self.scrollerY, 'mouseout', function () {
          self.ScrollFade(true);
        });
        self.addEvent(self.scrollerX, 'mouseout', function () {
          self.ScrollFade(true);
        });
        self.addEvent(window, 'mouseout', function () {
          self.ScrollFade(true);
        });
      }

      if (self.config.autoScrollInSeconds) {
        self.initAutoScrollInterval();
      }

      if (self.config.navBar) {
        if (self.config.itemsToShift) {
          function smartShift(type) {
            var wrapperScrollItems = self.node.children;

            var step, nearestLeftItem, totalItemsToShift, i;
            if (type === 'left') {
              nearestLeftItem = self.getNearestItem('left');
              step = -1 * nearestLeftItem.diff + 5;

              totalItemsToShift = self.config.itemsToShift - 1;

              for (i = nearestLeftItem.index - 1; i !== nearestLeftItem.index - 1 - totalItemsToShift; i--) {
                if (i < 0 || i >= wrapperScrollItems.length) {
                  break;
                }
                step += wrapperScrollItems[i].getBoundingClientRect().width;
              }
            } else if (type === 'right') {
              nearestLeftItem = self.getNearestItem('right');
              step = nearestLeftItem.diff - 5;

              totalItemsToShift = self.config.itemsToShift;

              for (i = nearestLeftItem.index; i !== nearestLeftItem.index + totalItemsToShift; i++) {
                if (i < 0 || i >= wrapperScrollItems.length) {
                  break;
                }
                step += wrapperScrollItems[i].getBoundingClientRect().width;
              }
            }

            self.navBarClick(type, step);
          }
          self.addEvent(self.navBar.left, 'mousedown', function (event) {
            smartShift('left');
          });
          self.addEvent(self.navBar.right, 'mousedown', function (event) {
            smartShift('right');
          });
        } else {
          self.addEvent(self.navBar.left, 'mousedown', function (event) {
            self.navBarClick('left');
          });
          self.addEvent(self.navBar.right, 'mousedown', function (event) {
            self.navBarClick('right');
          });
        }
      }

      if (self.config.fullWidth) {
        self.addEvent(window, 'resize', function () {
          self.updateFullWidth();
        });
      }
      if (self.config.enableTouchpadScrollX) {
        self.addEvent(self.node, 'wheel', function (event) {
          self.wheelX(event);
        });
      }

      self.initialOffset = {
        top: self.node.offsetTop,
        left: self.node.offsetLeft,
      };
    },
    getNode: function () {
      var self = this;
      return self.node;
    },
    render: function () {
      var self = this,
        wrapper,
        scrollerY,
        scrollareaY,
        scrollerX,
        scrollareaX,
        contentWrapper;
      if (!(self.node.parentNode.parentNode.className.split(' ').indexOf(self.config.wrapper) != -1)) {
        wrapper = document.createElement('div');
        scrollerY = document.createElement('div');
        scrollerX = document.createElement('div');
        scrollareaY = document.createElement('div');
        scrollareaX = document.createElement('div');
        contentWrapper = document.createElement('div');
        contentWrapper.className = self.config.content + '-contentwrapper';
        self.classList.add(self.node, self.config.content);
        wrapper.className = self.config.wrapper;
        self.node.parentNode.insertBefore(wrapper, self.node.nextSibling);
        scrollareaY.className = self.config.scrollarea + (self.config.scrollareaY ? ' ' + self.config.scrollareaY : '');
        scrollareaX.className = self.config.scrollarea + (self.config.scrollareaX ? ' ' + self.config.scrollareaX : '');
        scrollerY.className = self.config.scroller;
        scrollerX.className = self.config.scroller;
        contentWrapper.appendChild(self.node);
        contentWrapper.appendChild(scrollareaY).appendChild(scrollerY);
        contentWrapper.appendChild(scrollareaX).appendChild(scrollerX);
        wrapper.appendChild(contentWrapper);
        contentWrapper.style.width = self.config.width ? self.config.width : self.node.offsetWidth + 'px';
        contentWrapper.style.height = self.node.className.split(' ').indexOf(self.config.scrollbar_parent) != -1 ? '100%' : self.node.offsetHeight + 'px';

        self.node.style.width = self.node.style.height = 'auto';
        if (self.config.navBar) {
          var navBar = {
            left: document.createElement('div'),
            right: document.createElement('div'),
          };
          navBar.left.className = self.config.content + '-navBar ' + self.config.content + '-navBar__left';
          navBar.right.className = self.config.content + '-navBar ' + self.config.content + '-navBar__right';
          wrapper.appendChild(navBar.left);
          wrapper.appendChild(navBar.right);
        }
      } else {
        contentWrapper = self.node.parentNode;
        // [94252] Ошибки в консоле в вебчиталке+ Неработает настройка шрифтов
        wrapper = self.contentWrapper ? self.contentWrapper.parentNode : contentWrapper;
        scrollareaY = wrapper.querySelector('.' + self.config.scrollarea + (self.config.scrollareaY ? '.' + self.config.scrollareaY : ''));
        scrollareaX = wrapper.querySelector('.' + self.config.scrollarea + (self.config.scrollareaX ? '.' + self.config.scrollareaX : ''));
        scrollerY = scrollareaY.querySelector('.' + self.config.scroller);
        scrollerX = scrollareaX.querySelector('.' + self.config.scroller);
        if (self.config.navBar) {
          var navBar = {
            left: self.navBar.left,
            right: self.navBar.right,
          };
        }
      }
      if (!self.config.show) self.scrollOpacity = scrollareaY.style.opacity = scrollareaX.style.opacity = 0;
      self.wrapper = wrapper;
      self.scrollerY = scrollerY;
      self.scrollerX = scrollerX;
      self.scrollareaY = scrollareaY;
      self.scrollareaX = scrollareaX;
      self.contentWrapper = contentWrapper;
      self.fadeInterval = null;
      if (self.config.navBar) {
        self.navBar = {
          left: navBar.left,
          right: navBar.right,
        };
      }
      if (self.config.fullWidth) {
        self.markersWrapper = self.config.markersWrapper || self.wrapper;
        self.renderMarkers();
        self.updateFullWidth();
      }
    },
    deltaXMultiplier: 1, // специальный множитель, не изменяемый при ресайзе
    deltaYMultiplier: 1, // при -1 можно получить обратный скролл
    refresh: function () {
      var self = this;

      self.deltaY = (self.deltaYMultiplier * self.contentWrapper.offsetHeight) / self.node.offsetHeight;
      self.deltaX = (self.deltaXMultiplier * self.contentWrapper.offsetWidth) / self.node.offsetWidth;

      /* vertical */
      self.scrollerY.style.height = self.deltaY > 1 ? self.scrollareaY.offsetHeight : Math.round(self.deltaY * self.scrollareaY.offsetHeight) + 'px';

      if (self.contentWrapper.offsetHeight >= self.node.offsetHeight) {
        self.scrollareaY.style.display = 'none';
        self.node.style.top = 0;
      } else {
        self.scrollareaY.style.display = 'block';
      }

      /* horizontal */
      self.scrollerX.style.width = self.deltaX > 1 ? self.scrollareaX.offsetWidth : Math.round(self.deltaX * self.scrollareaX.offsetWidth) + 'px';
      //debugger;
      if (self.deltaX >= 1) {
        self.scrollareaX.style.display = 'none';
        self.node.style.left = 0;
        if (self.config.navBar) {
          if (self.config.removeNavBarIfPossible === true) {
            self.navBar.left.style.display = 'none';
            self.navBar.right.style.display = 'none';
            self.node.style.marginTop = 0;
            self.navBarIsVisible = false;
          }

          self.classList.remove(self.navBar.left, self.config.content + '-navBar_active');
          self.classList.remove(self.navBar.right, self.config.content + '-navBar_active');
          self.contentWrapper.dataset.left = false;
          self.contentWrapper.dataset.right = false;
        }
      } else {
        self.scrollareaX.style.display = 'block';

        if (self.config.navBar) {
          if (self.config.removeNavBarIfPossible === true) {
            self.navBar.left.style.display = 'block';
            self.navBar.right.style.display = 'block';
            self.navBarIsVisible = true;
            //self.node.style.marginTop = '40px';
          }

          if (self.node.offsetLeft < 0 && !self.isLeftBorder) {
            self.classList.add(self.navBar.left, self.config.content + '-navBar_active');
            self.contentWrapper.dataset.left = true;
          } else {
            self.classList.remove(self.navBar.left, self.config.content + '-navBar_active');
            self.contentWrapper.dataset.left = false;
          }
          if (self.node.offsetWidth + self.node.offsetLeft > self.contentWrapper.offsetWidth && !self.isRightBorder) {
            self.classList.add(self.navBar.right, self.config.content + '-navBar_active');
            self.contentWrapper.dataset.right = true;
          } else {
            self.classList.remove(self.navBar.right, self.config.content + '-navBar_active');
            self.contentWrapper.dataset.right = false;
          }
        }
      }
    },
    drop: function (event) {
      var self = this;
      if (!self.isDragging) {
        return;
      } else {
        self.isDragging = false;
      }

      event = event || window.event;
      self.canDrag = false;
      if (!self.config.show) self.ScrollFade(false);
      self.scrollareaX.classList.remove(self.config.scrollareaX + '_active');
      self.anchorScrollItem();
    },
    moveY: function (event) {
      var self = this;
      event = event || window.event;
      self.refresh();
      if (self.canDrag) {
        self.setPosition({ y: (self.shift_y - event.clientY) / self.deltaY });
        self.blockEvent(event);
        self.scrollshow = true;
      }
      return false;
    },
    moveX: function (event) {
      var self = this;
      if (self.isBusy === true) {
        return;
      }
      event = event || window.event;
      self.refresh();
      if (self.canDrag) {
        self.setPosition({ x: (self.shift_x - event.clientX) / self.deltaX });
        self.blockEvent(event);
        self.scrollshow = true;
      }
      return false;
    },
    /*
     * @object newPosition {x, y}
     * @boolean isFromUIButton
     */
    setPosition: function (newPosition, isFromUIButton) {
      var self = this;

      if (newPosition.hasOwnProperty('y')) {
        //debugger;
        if (newPosition.y >= self.contentWrapper.offsetHeight - self.node.offsetHeight && newPosition.y <= 0) {
          self.node.style.top = newPosition.y + 'px';
        } else if (newPosition.y < self.contentWrapper.offsetHeight - self.node.offsetHeight) {
          self.node.style.top = self.contentWrapper.offsetHeight - self.node.offsetHeight + 'px';
        } else {
          self.node.style.top = 0 + 'px';
        }
        self.scrollerY.style.top = Math.round(((parseInt(self.node.style.top) * self.scrollareaY.offsetHeight) / self.node.offsetHeight) * -1) + 'px';
        //self.scrollerY.style.top =  + "px";
      }

      if (newPosition.hasOwnProperty('x')) {
        var newPosX = newPosition.x;
        var doPingEffectOnFinish = false;

        if (newPosition.x > self.contentWrapper.offsetWidth - self.node.offsetWidth && newPosition.x < 0) {
          self.isLeftBorder = false;
          self.isRightBorder = false;
          newPosX = newPosition.x;
        } else if (newPosition.x >= 0) {
          newPosX = 0;

          if (self.config.showPingEffect && isFromUIButton && !self.isLeftBorder) {
            doPingEffectOnFinish = true;
          }

          self.isLeftBorder = true;
        } else if (newPosition.x <= self.contentWrapper.offsetWidth - self.node.offsetWidth) {
          newPosX = self.contentWrapper.offsetWidth - self.node.offsetWidth;

          if (self.config.showPingEffect && isFromUIButton && !self.isRightBorder) {
            doPingEffectOnFinish = true;
          }

          self.isRightBorder = true;
        }

        if (!self.isDoingPingEffect) {
          self.node.style.left = newPosX + 'px';
          self.scrollerX.style.left = Math.round(((parseInt(self.node.style.left) * self.scrollareaX.offsetWidth) / self.node.offsetWidth) * -1) + 'px';
        }

        if (doPingEffectOnFinish === true) {
          self.isBarClicked = true;
          if (self.isRightBorder) {
            self.doPingEffect(newPosX, -30);
          } else if (self.isLeftBorder) {
            self.doPingEffect(newPosX, 30);
          }
        }
      }

      return false;
    },

    isDoingPingEffect: false,
    doPingEffect: function (currentPosition, delta) {
      var self = this;
      self.isDoingPingEffect = true;

      var arrOfShitfs = [];

      var deltaValue = 2.5;

      if (delta >= 0) {
        for (var i = 0; i < delta; i += deltaValue) {
          arrOfShitfs.push(i);
        }
        for (var i = delta; i >= 0; i -= deltaValue) {
          arrOfShitfs.push(i);
        }
      } else {
        for (var i = 0; i > delta; i -= deltaValue) {
          arrOfShitfs.push(i);
        }
        for (var i = delta; i <= 0; i += deltaValue) {
          arrOfShitfs.push(i);
        }
      }

      function performShift(shiftIndex) {
        setTimeout(function () {
          self.node.style.left = currentPosition + arrOfShitfs[shiftIndex] + 'px';
          if (shiftIndex != arrOfShitfs.length - 1) {
            performShift(shiftIndex + 1);
          } else {
            self.isDoingPingEffect = false;
            self.isBarClicked = false;
            self.isBusy = false;
          }
        }, 10);
      }

      performShift(arrOfShitfs[0]);
    },
    setPositionForce: function (state) {
      var self = this;
      var top = 0;
      switch (state) {
        case 'top':
          top = 0;
          break;
        case 'bottom':
          top = 0;
          break;
      }
      self.scrollerY.style.top = top + 'px';
      self.node.style.top = top + 'px';
      return false;
    },
    blockEvent: function (event) {
      var self = this;
      event = event || window.event;
      if (event.stopPropagation) event.stopPropagation();
      else event.cancelBubble = true;
      if (event.preventDefault) event.preventDefault();
      else event.returnValue = false;
    },
    dragY: function (event) {
      var self = this;
      self.isDragging = true;

      event = event || window.event;
      self.canDrag = true;

      self.shift_y = event.clientY + parseInt(self.node.offsetTop) * self.deltaY;

      if (!self.config.show) self.fadeIn(false);
      self.blockEvent(event);
      return false;
    },
    dragX: function (event) {
      var self = this;
      self.isDragging = true;

      if (self.isBusy === true) {
        return;
      }
      event = event || window.event;
      self.canDrag = true;

      self.shift_x = event.clientX + parseInt(self.node.offsetLeft) * self.deltaX;

      if (!self.config.show) self.fadeIn(false);
      self.blockEvent(event);
      self.scrollareaX.classList.add(self.config.scrollareaX + '_active');
      return false;
    },

    prevSwipeTouch: { x: null, y: null },
    currentSwipeDone: false,
    swipeTouchStart: function (event) {
      var self = this;
      event = event || window.event;

      self.prevSwipeTouch.x = event.touches[0].clientX;
      self.prevSwipeTouch.y = event.touches[0].clientY;
    },
    swipeTouchMove: function (event) {
      var self = this;
      event = event || window.event;

      if (!self.prevSwipeTouch.x || !self.prevSwipeTouch.y) {
        return;
      }

      var xUp = event.touches[0].clientX;
      var yUp = event.touches[0].clientY;

      var xDiff = self.prevSwipeTouch.x - xUp;
      var yDiff = self.prevSwipeTouch.y - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (!self.isBarClicked && !self.currentSwipeDone) {
          self.currentSwipeDone = true;
          if (xDiff > 0) {
            self.navBarClick('right');
          } else {
            self.navBarClick('left');
          }
        }
        event.preventDefault();
        return false;
      }

      self.prevSwipeTouch.x = null;
      self.prevSwipeTouch.y = null;
    },
    swipeTouchEnd: function () {
      var self = this;

      self.currentSwipeDone = false;
    },

    /**
     * Обаботчик события для обычного скролла (старт сролла)
     * @param {TouchEvent} event
     */
    scrollTouchStart: function (event) {
      event = event || window.event;
      this.previousTouch = {
        y: event.touches[0].pageY,
        x: event.touches[0].pageX,
      };

      if (event.type === 'touchstart') {
        return true;
      }
      event.preventDefault();
    },

    /**
     * Обаботчик события для обычного скролла (движение сролла)
     * @param {TouchEvent} event
     */
    scrollTouchMove: function (event) {
      this.refresh();
      event = event || window.event;
      var currentTouch = {
        y: event.touches[0].pageY,
        x: event.touches[0].pageX,
      };
      var horizontalDifference = currentTouch.x - this.previousTouch.x;
      var verticalDifference = currentTouch.y - this.previousTouch.y;

      // текущее отклонение высчитываем как отклонение минус изначальное отклонение (на случай, если у враппера были разного рода отступы и тд)
      var currentOffset = {
        left: this.node.offsetLeft - this.initialOffset.left,
        top: this.node.offsetTop - this.initialOffset.top,
      };
      var newPosition = {};

      // смотрим, доступен ли нам скролл по всем осям, если только по одной, то проверяем, является разница скролла нужной нам оси *преобладающей* в текущем скролле
      var bothAxesEnabled = this.config.enabledTouchAxes.x === true && this.config.enabledTouchAxes.y === true;
      if (bothAxesEnabled || (this.config.enabledTouchAxes.x === true && Math.abs(horizontalDifference) > Math.abs(verticalDifference))) {
        newPosition.x = currentOffset.left + horizontalDifference / this.deltaX;
      }
      if (bothAxesEnabled || (this.config.enabledTouchAxes.y === true && Math.abs(verticalDifference) > Math.abs(horizontalDifference))) {
        newPosition.y = currentOffset.top + verticalDifference / this.deltaY;
      }
      this.previousTouch = currentTouch;
      // если нам есть что обновлять и текущий ивент в принципе отменяем - действуем!
      if ((newPosition.x || newPosition.y) && event.cancelable) {
        this.setPosition(newPosition);
        if (!this.config.show) this.fadeIn(false);

        this.blockEvent(event);
        return false;
      }
    },
    wheel: function (event) {
      var self = this;
      //debugger;
      self.refresh();
      event = event || window.event;
      var wheelDelta = 0;
      var step = 15;
      var newPosition = {};
      if (event.wheelDelta) {
        wheelDelta = event.wheelDelta / 120;
      } else if (event.detail) {
        wheelDelta = -event.detail / 3;
      }

      if (wheelDelta) {
        //debugger;
        var currentPositionY = self.node.offsetTop;
        newPosition.y = currentPositionY + wheelDelta * step;

        if ((wheelDelta > 0 && self.node.offsetLeft < 0) || (wheelDelta < 0 && self.node.offsetLeft > self.contentWrapper.offsetWidth - self.node.offsetWidth)) {
          // horizontal scroll
          // пока не нужен
          //var currentPositionX = parseInt(self.node.offsetLeft);
          //newPosition.x = currentPositionX + wheelDelta*step;
        }
      }
      if (!self.config.show) {
        self.fadeIn(false);
      }

      self.setPosition(newPosition);

      self.refresh();

      //if (!((self.node.offsetTop == 0 || self.node.offsetLeft == 0 || self.node.offsetTop  == self.contentWrapper.offsetHeight - self.node.offsetHeight || self.node.offsetLeft  == self.contentWrapper.offsetWidth - self.node.offsetWidth) && self.config.scrollPage)) {

      if (!(((self.node.offsetTop == 0 && wheelDelta > 0) || (self.node.offsetTop == self.contentWrapper.offsetHeight - self.node.offsetHeight && wheelDelta < 0)) && self.config.scrollPage)) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        event.returnValue = false;
        self.blockEvent(event);
      }
    },
    wheelX: function (event) {
      var self = this;
      if (!self.navBarIsVisible) {
        return;
      }

      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        self.blockEvent(event);
        var diffX = event.deltaX;
        var currentOffset = self.node.offsetLeft;
        self.setPosition({ x: currentOffset - diffX });
        self.refresh();
      }
    },
    areaclickY: function (event) {
      var self = this;
      event = event || window.event;

      var clickScrollY = event.clientY - self.scrollareaY.getBoundingClientRect().top - self.scrollerY.offsetHeight / 2;

      self.shift_y = (clickScrollY / self.deltaY) * -1;
      self.setPosition({ y: self.shift_y });
      if (!self.config.show) self.ScrollFade(false);
      self.blockEvent(event);
      return false;
    },
    areaclickX: function (event) {
      var self = this;
      if (self.isBusy === true) {
        return;
      }
      event = event || window.event;

      var clickScrollX = event.clientX - self.scrollareaX.getBoundingClientRect().left - self.scrollerX.offsetWidth / 2;

      self.shift_x = (clickScrollX / self.deltaX) * -1;
      self.setPosition({ x: self.shift_x });
      if (!self.config.show) self.ScrollFade(false);
      self.blockEvent(event);
      return false;
    },
    areatouchX: function (event) {
      var self = this;
      event = event || window.event;

      var clickScrollX = event.touches[0].pageX - self.scrollareaX.getBoundingClientRect().left - self.scrollerX.offsetWidth / 2;

      self.shift_x = (clickScrollX / self.deltaX) * -1;
      self.setPosition({ x: self.shift_x });

      self.refresh();

      if (!self.config.show) self.ScrollFade(false);
      self.blockEvent(event);
      return false;
    },

    fadeIn: function (scrollhover) {
      var self = this;
      self.scrollshow = true;
      if (scrollhover) self.scrollHover = true;
      self.scrollOpacity += 0.1;
      self.scrollareaY.style.opacity = self.scrollOpacity;
      if (self.scrollOpacity > 0.9) {
        self.scrollareaY.style.opacity = 1;
        if (!self.scrollHover)
          setTimeout(function () {
            self.ScrollFade(false);
          }, 500);
      } else {
        setTimeout(function () {
          self.fadeIn(false);
        }, 50);
      }
    },
    fadeOut: function () {
      var self = this;
      if (!self.scrollshow) {
        self.scrollOpacity -= 0.1;
        self.scrollareaY.style.opacity = self.scrollOpacity;
        if (self.scrollOpacity < 0.2) {
          self.scrollareaY.style.opacity = 0.0;
        } else {
          setTimeout(function () {
            self.fadeOut();
          }, 100);
        }
      }
    },
    ScrollFade: function (scrollhover) {
      var self = this;
      self.scrollshow = false;
      if (scrollhover) self.scrollHover = false;
      if (!self.scrollHover) {
        clearInterval(self.fadeInterval);
        self.fadeInterval = setTimeout(function () {
          self.fadeOut();
        }, 3000);
      }
    },
    Destroy: function (self) {
      self = self || this;
      if (self.node.className.split(' ').indexOf(self.config.scrollbar_parent) != -1 && self.node.parentNode.className.split(' ').indexOf(self.config.wrapper) != -1) {
        self.classList.remove(self.node, self.config.content);
        self.contentWrapper.parentNode.removeChild(self.contentWrapper.parentNode.querySelector('.' + self.config.scrollarea));
        var fragment = document.createDocumentFragment();
        var cont = self.contentWrapper.parentNode.parentNode;
        fragment.appendChild(self.node);
        cont.replaceChild(fragment, self.contentWrapper);
      }
    },
    /*
     *@param boolean - отработала ли перемотка слайдера
     */
    isBarClicked: false,
    /*
     * @param type [left|right] влево или вправо сдвиг
     * @param {number} (optional) step шаг в пикселях
     * @param callbackFunction {function} функция, которую нужно вызвать по окончании сдвига
     */
    navBarClick: function (type, step, callbackFunction) {
      var self = this;
      if (self.isBusy === true) {
        return;
      }
      self.isBusy = true;

      if (self.isBarClicked) {
        self.blockEvent(event);
        return;
      }

      self.isBarClicked = true;

      //debugger;
      self.refresh();

      if (self.isRightBorder && type === 'right') {
        if (self.config.isLooped) {
          self.performLoopedTransition('first');
        } else {
          self.doPingEffect(self.contentWrapper.offsetWidth - self.node.offsetWidth, -30);
        }
        return;
      } else if (self.isLeftBorder && type === 'left') {
        if (self.config.isLooped) {
          self.performLoopedTransition('last');
        } else {
          self.doPingEffect(0, 30);
        }
        return;
      }

      step = step || self.contentWrapper.offsetWidth; // по дефолту сдвиг на весь экран
      if (type === 'right') {
        step = step * -1;
      }

      var to = self.node.offsetLeft + step;

      if (self.config.fullWidth) {
        var slide = self.slide;
        var slides = self.slides;
        if (type === 'right') {
          nextSlide = slide + 1;
          if (nextSlide > self.slides) {
            nextSlide = slide;
          }
        } else {
          nextSlide = slide - 1;
          if (nextSlide < 1) {
            nextSlide = slide;
          }
        }
        self.setSlideX(nextSlide);
      } else {
        callbackFunction = callbackFunction || function () {};
        if (self.config.itemsToShift) {
          self.sinusMovement(self.node.offsetLeft, to, self.node.offsetLeft, callbackFunction);
        } else {
          self.setPositionSmooth(to, 200, true, function () {
            self.isBusy = false;
            callbackFunction();
          });
        }
      }

      return false;
    },

    /**
     * Создаст эффект карусели
     *
     * Идея: копируем первый элемент и вставляем его в самый конец, далее плавно переходим на него, после чего резко переходим на оргинальный первый элемент, удаляя скопированный элемент
     * С последним элементом аналогично
     * @param direction {String} : "last", если нужно перейти к последнему; "first", если к первому
     */
    performLoopedTransition: function (direction) {
      var self = this;

      var artsSelector = self.node.childNodes;
      var clonedElement;
      var callback;
      if (direction === 'last') {
        clonedElement = artsSelector[artsSelector.length - 1].cloneNode(true);
        self.node.insertBefore(clonedElement, artsSelector[0]);
        self.setPosition({
          x: clonedElement.offsetWidth * -1,
        });

        self.setSlideXui(self.slides);
        callback = function () {
          clonedElement.remove();
          self.setPosition({
            x: artsSelector[artsSelector.length - 1].offsetLeft * -1 + 0.1,
          });
          self.setSlideX(self.slides || artsSelector.length);
          self.refresh();
          self.isBusy = false;
        };

        if (self.config.sinusMovementOptions.enabled) {
          self.sinusMovement(self.node.offsetLeft, 0, self.node.offsetLeft, callback);
        } else {
          self.setPositionSmooth(0, 200, true, callback);
        }
      } else if (direction === 'first') {
        clonedElement = artsSelector[0].cloneNode(true);
        self.node.insertBefore(clonedElement, artsSelector[artsSelector.length - 1].nextSibling);

        self.setSlideXui(1);
        callback = function () {
          self.setPosition({
            x: -0.1,
          });
          clonedElement.remove();
          self.setSlideX(1);
          self.refresh();
          self.isBusy = false;
        };

        if (self.config.sinusMovementOptions.enabled) {
          self.sinusMovement(self.node.offsetLeft, self.node.offsetLeft - clonedElement.offsetWidth, self.node.offsetLeft, callback);
        } else {
          self.setPositionSmooth(self.node.offsetLeft - clonedElement.offsetWidth, 200, true, callback);
        }
      }
    },
    setPositionSmooth: function (to, duration, isFromUIButton, callbackFunction) {
      var self = this;
      if (duration <= 0) {
        self.isBarClicked = false;
        if (callbackFunction) {
          callbackFunction();
        }
        return;
      }
      var difference = to - self.node.offsetLeft;
      var perTick = (difference / duration) * 10;
      setTimeout(function () {
        self.setPosition(
          {
            x: self.node.offsetLeft + perTick,
          },
          isFromUIButton !== undefined
        );
        self.refresh();
        if (self.node.offsetLeft == to) {
          self.isBarClicked = false;
          if (callbackFunction) {
            callbackFunction();
          }
          return;
        }
        self.setPositionSmooth(to, duration - 10, isFromUIButton !== undefined, callbackFunction);
      }, 10);
    },

    /**
     * Создаст плавное движение с использованием любой функции для задания скорости в конкретной точке
     *
     * @param {number} from
     * @param {number} to
     * @param {number} current
     * @param {function} speedFunction
     * @param {function} callbackFunction
     */
    setPositionFunctional: function (from, to, current, speedFunction, callbackFunction) {
      var self = this;
      var currentRatio = Math.abs((current - from) / (to - from));
      var speedPerTick = Math.round(speedFunction(currentRatio));
      var isBorderAlready = (self.isLeftBorder || self.isRightBorder) && currentRatio !== 0;
      if (isBorderAlready || currentRatio >= 1) {
        self.isBarClicked = false;
        if (callbackFunction) {
          callbackFunction();
        }
        return;
      }
      if (from > to) {
        speedPerTick *= -1;
      }
      var tickMs = 1000 / 60;
      var newPosition = self.node.offsetLeft + speedPerTick;
      setTimeout(function () {
        self.setPosition({
          x: newPosition,
        });
        self.refresh();
        if (self.node.offsetLeft === to) {
          self.isBarClicked = false;
          if (callbackFunction) {
            callbackFunction();
          }
          return;
        }
        self.setPositionFunctional(from, to, current + speedPerTick, speedFunction, callbackFunction);
      }, tickMs);
    },
    sinusMovement: function (from, to, current, callbackFunction) {
      var self = this;
      callbackFunction = callbackFunction || function () {};

      self.setPositionFunctional(
        from,
        to,
        current,
        function (ratio) {
          return Math.sin(Math.PI * (self.config.sinusMovementOptions.arg1 * ratio + self.config.sinusMovementOptions.arg2)) * self.config.sinusMovementOptions.speed;
        },
        function () {
          self.isBusy = false;
          callbackFunction();
        }
      );
    },
    updateFullWidth: function (e) {
      var self = this;
      for (var i = 0; i < self.node.childNodes.length; i++) {
        self.node.childNodes[i].style.width = self.contentWrapper.offsetWidth + 'px';
      }
      self.setSlideX(self.slide);
    },
    renderMarkers: function () {
      var self = this;
      var markers = document.createElement('div');
      markers.className = self.config.content + '-markers';
      for (var i = 0; i < self.node.childNodes.length; i++) {
        var marker = document.createElement('div');
        var slide = i + 1;
        marker.className = self.config.content + '-marker ' + self.config.content + '-marker_' + slide;
        marker.dataset.slideX = slide;
        if (i === 0 && i === self.node.childNodes.length - 1) {
          markers.style.display = 'none';
        }
        self.addMarkerEvent(marker, slide);
        markers.appendChild(marker);
      }
      self.markers = markers;
      self.markersWrapper.appendChild(markers);
    },
    addMarkerEvent: function (marker, slide) {
      var self = this;
      self.addEvent(marker, 'mousedown', function () {
        if (self.config.autoScrollInSeconds) {
          self.clearAutoScrollInterval();
          self.initAutoScrollInterval();
        }
        self.setSlideX(slide);
      });
    },
    setSlideX: function (slide) {
      if (!slide) {
        return;
      }
      var self = this;
      var fullWidth = self.contentWrapper.offsetWidth;
      //var slides = self.node.childNodes.length;
      var position = (slide - 1) * fullWidth;

      self.setSlideXui(slide);
      self.slide = slide;

      if (self.config.sinusMovementOptions.enabled) {
        self.sinusMovement(self.node.offsetLeft, position * -1, self.node.offsetLeft);
      } else {
        self.setPositionSmooth(position * -1, 200, true, function () {
          self.isBusy = false;
        });
      }
    },
    setSlideXui: function (slide) {
      var self = this;

      if (self.markers) {
        self.classList.remove(self.markers.querySelector('[data-slide-x="' + self.slide + '"]'), self.config.content + '-marker_active');
        self.classList.add(self.markers.querySelector('[data-slide-x="' + slide + '"]'), self.config.content + '-marker_active');
      }
    },
    isMouseDown: false,
    onDragStartHandler: function (e) {
      var self = this;
      if (!self.navBarIsVisible) {
        return;
      }

      self.isMouseDown = true;

      // определяем callback так, чтобы его потом можно было удалить
      self.dragControlFunction = function (event) {
        self.dragX(event);
      };
      setTimeout(function () {
        if (self.isMouseDown) {
          self.wrapper.classList.add('is-dragging-cursor');
          document.body.style.cursor = 'move';
          self.addEvent(window, 'mousemove', self.dragControlFunction);
          self.deltaXMultiplier = -1;
        }
      }, self.config.dragBeginTimeout);
    },
    onDragEndHandler: function (e) {
      var self = this;
      if (!self.navBarIsVisible) {
        return;
      }

      if (self.isMouseDown === true) {
        self.isMouseDown = false;

        self.wrapper.classList.remove('is-dragging-cursor');
        document.body.style.cursor = 'default';
        self.removeEvent(window, 'mousemove', self.dragControlFunction);
        self.deltaXMultiplier = 1;
        //self.anchorScrollItem();
      }
    },
    isBusy: false,
    anchorScrollItem: function (callbackFunction) {
      var self = this;
      if (self.isBusy === true || self.config.anchorItems !== true) {
        return;
      }
      self.isBusy = true;

      // в крайнем правом положении к левому краю элемент не прикрепляем
      if (self.isRightBorder === true) {
        self.isBusy = false;
        return;
      }

      var wrapperBounds = self.wrapper.getBoundingClientRect();
      var nearestLeftItem = self.getNearestItem();

      var diff = wrapperBounds.left - nearestLeftItem.left;

      // учитываем градиент
      if (nearestLeftItem.index !== 0) {
        diff += self.config.anchorItemsOffset.center;
      } else {
        diff += self.config.anchorItemsOffset.begin;
      }
      self.setPositionSmooth(self.node.offsetLeft + diff, 200, false, function () {
        self.isBusy = false;
        if (callbackFunction) {
          callbackFunction();
        }
      });
    },

    /**
     * @typedef {Object} ScrollbarNearestItem
     * @property {number} index - индекс найденного элемента
     * @property {number} left - абсолютное положение левого края найденного элемента (в px)
     * @property {number} diff - расстояние между абсолютным положением левого края найденного элемента и левым краем враппера (в px), может быть отрицательным
     */

    /**
     * Найдёт элемент, который ближе всего к видимому началу скроллбара
     *
     * @param {string} mode - "left", если нужно найти самый близкий только слева от границы враппера; "right" соответственно для самого правого элемента
     *
     * @return {ScrollbarNearestItem}
     */
    getNearestItem: function (mode) {
      var wrapperLeft = this.wrapper.getBoundingClientRect().left;
      var wrapperItems = this.node.children;

      var nearest = { index: -1, left: 0, diff: 10000 };
      for (var i = 0; i < wrapperItems.length; i++) {
        var currentLeft = wrapperItems[i].getBoundingClientRect().left;
        var currentDiff = currentLeft - wrapperLeft;
        if (mode === 'left' && currentLeft > wrapperLeft) {
          continue;
        }
        if (mode === 'right' && currentLeft < wrapperLeft) {
          continue;
        }

        if (Math.abs(currentDiff) < Math.abs(nearest.diff)) {
          nearest.diff = currentDiff;
          nearest.left = currentLeft;
          nearest.index = i;
        }
      }

      return nearest;
    },

    initAutoScrollInterval: function () {
      var self = this;

      self.autoScrollInterval = setInterval(function () {
        self.navBarClick('right');
      }, self.config.autoScrollInSeconds * 1000);
    },

    clearAutoScrollInterval: function () {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
      }
    },
  };
  if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (elt /*, from*/) {
      var len = this.length >>> 0;

      var from = Number(arguments[1]) || 0;
      from = from < 0 ? Math.ceil(from) : Math.floor(from);
      if (from < 0) from += len;

      for (; from < len; from++) {
        if (from in this && this[from] === elt) return from;
      }
      return -1;
    };
  }

  var scrollbarList = ref.querySelectorAll('.scrollbar_art');
  var params = {
    show: false,
    scrollbar_parent: 'scrollbar_parent',
    width: 'auto',
    navBar: true,
    scrollPage: true,
    wrapper: 'scrollwrapper scrollwrapper_v2',
    showPingEffect: true,
    enabledTouchAxes: { x: true },
    removeNavBarIfPossible: true,
  };

  scrollbarList.forEach(function (elem) {
    new scrollbar(elem, params);
  });
};
