import { FC, useEffect, useRef } from 'react';
import Layout from '@/components/layout/Layout';
import Link from 'next/link';
import { parseISO, format } from 'date-fns';
import BookCardRating from '@/components/ui/book-card-rating/BookCardRating';
import BookCardItem from '@/components/ui/book-card-item/BookCardItem';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { ITopAuthors } from '@/interfaces/topAuthors.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { ILastReviews } from '@/interfaces/lastReviews';
import { IGenresData } from '@/interfaces/genres.interface';
import { getAuthorFullName, getTruncatedTxt, hideImgOnError } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { oldSlider } from '@/helpers/slider';
import styles from './Home.module.scss';

interface IHomePage {
  popularBooks: IBooksPageData[];
  newBooks: IBookPage;
  topAuthors: ITopAuthors;
  reviews: ILastReviews;
  advertisingBlock: IAdvertisingBlock;
  menuGenres: IGenresData[];
}

const Home: FC<IHomePage> = ({ popularBooks, newBooks, topAuthors, reviews, advertisingBlock, menuGenres }) => {
  //seo block
  const SEO_TITLE = 'ЛитПортал – библиотека электронных книг – скачать книги бесплатно или читать онлайн';
  const SEO_DESCRIPTION = 'ЛитПортал предлагает вам огромный выбор электронных и аудиокниг, которые вы можете скачать или читать онлайн бесплатно.';

  const refSliderContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    oldSlider(refSliderContent.current);
  }, []);

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref="/" menuGenres={menuGenres}>
      <div ref={refSliderContent}>
        <h2 className="h2">Горячие книжные новинки</h2>
        <div className={styles.slider}>
          <div className="scrollbar_art scrollbar scrollbar_parent">
            {newBooks.data.map((item) => {
              const bookLink = `/avtory/${item.author.alias}/${item.alias}/`;
              const bookTitle = item.book.title;
              return (
                <div key={item.id} className="scroll_item">
                  <Link prefetch={false} className={styles.slider__imgWrap} href={bookLink} draggable={false}>
                    <img className={styles.slider__img} height="260" width="182" alt={bookTitle} src={`${DEV_ABSOLUTE_URL}/covers_185/${item.book.external_id}.jpg`} draggable="false" onError={hideImgOnError} />
                  </Link>
                  <Link prefetch={false} className={styles.slider__title} href={bookLink}>
                    {getTruncatedTxt(bookTitle, 55)}
                  </Link>
                  <Link prefetch={false} className={styles.slider__author} href={`/avtory/${item.author.alias}/`}>
                    {getAuthorFullName(item.author.first_name, item.author.middle_name, item.author.last_name)}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <h2 className="h2">Популярные авторы</h2>
        <div className={styles.authors}>
          {topAuthors.slice(0, 25).map((item) => (
            <Link prefetch={false} key={item.id} className={styles.authors__item} href={`/avtory/${item.alias}/`}>
              {getAuthorFullName(item.first_name, String(item.middle_name), item.last_name)}
            </Link>
          ))}
          <Link prefetch={false} className={`${styles.authors__item} ${styles.authors__item_all}`} href="/avtory/">
            Показать больше авторов
          </Link>
        </div>

        <h2 className="h2">Книги на Литрес</h2>
        <div className={styles.slider}>
          <div className="scrollbar_art scrollbar scrollbar_parent">
            {advertisingBlock.map((item) => {
              //фикс урлов с пробелом перед https
              const litresUrl = `https${item.litres_url.split('https')[1]}`;
              return (
                <div key={item.id} className={`scroll_item`}>
                  <Link prefetch={false} className={styles.slider__imgWrap} href={litresUrl} draggable={false}>
                    <img className={styles.slider__img} height="260" width="182" alt={item.title} src={`${DEV_ABSOLUTE_URL}/covers_185/${item.external_id}.jpg`} draggable="false" onError={hideImgOnError} />
                  </Link>
                  <Link prefetch={false} className={styles.slider__title} href={litresUrl}>
                    {item.title}
                  </Link>
                  {/* <Link prefetch={false} className={styles.slider__author} href={`/avtory`}>
                    {getAuthorFullName(item.author.first_name, item.author.middle_name, item.author.last_name)}
                  </Link> */}
                </div>
              );
            })}
          </div>
        </div>

        <h2 className="h2">Последние отзывы</h2>
        <div className={`${styles.lastReviews} lastReviews`}>
          <div className="scrollbar_art scrollbar scrollbar_parent">
            {reviews.map((review) => {
              const bookAlias = `/avtory/${review.author_alias}/${review.book_alias}`;
              return (
                <div key={review.id} className="scroll_item">
                  <div className={styles.lastReviews__item}>
                    <div className={styles.lastReviews__card}>
                      <div className={styles.lastReviews__cardImg}>
                        <Link prefetch={false} className={styles.lastReviews__cardLink} href={bookAlias}>
                          <img src={`${DEV_ABSOLUTE_URL}/covers_185/${review.external_id}.jpg`} alt={review.book_title} width="64" height="90" onError={hideImgOnError} />
                        </Link>
                      </div>
                      <div className={styles.lastReviews__cardDesc}>
                        <Link prefetch={false} className={styles.lastReviews__book} href={bookAlias}>
                          {review.book_title}
                        </Link>
                        <div className={styles.lastReviews__author}>{getAuthorFullName(review.author_first_name, String(review.author_middle_name), review.author_last_name)}</div>
                        <BookCardRating rating={review.rating} extraClassName="bookCardRating_small" />
                      </div>
                    </div>
                    <div className={styles.lastReviews__info}>
                      <div className={styles.lastReviews__user}>{review.name}</div>
                      <div className={styles.lastReviews__date}>
                        <time dateTime={review.created_at}>{format(parseISO(review.created_at), 'dd.MM.yyyy')}</time>
                      </div>
                      <div className={styles.lastReviews__text}>{getTruncatedTxt(review.message, 150)}</div>
                      {review.message.length > 150 && (
                        <Link prefetch={false} className={styles.lastReviews__btn} href={bookAlias}>
                          Далее
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <h2 className="h2">Популярные произведения</h2>
        <div className="bookCardItemsWrap">
          {popularBooks.map((book) => (
            <BookCardItem
              extraClassName="bookCardItem_short"
              key={book.id}
              book={{ alias: book.alias, external_id: book.book.external_id, title: book.book.title, type: book.type, rating: book.rating, description: book.book.description }}
              author={{ alias: book.author.alias }}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Home;
