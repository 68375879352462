import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, MENU_API, REVALIDATE_TIME } from '@/helpers/variables';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { ITopAuthors } from '@/interfaces/topAuthors.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { ILastReviews } from '@/interfaces/lastReviews';
import { IGenresData } from '@/interfaces/genres.interface';
import { REVIEWS_FOR_SLIDER } from '@/helpers/variables';

interface IHomePage {
  popularBooks: IBooksPageData[];
  newBooks: IBookPage;
  topAuthors: ITopAuthors;
  reviews: ILastReviews;
  advertisingBlock: IAdvertisingBlock;
  menuGenres: IGenresData[];
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks, topAuthors, reviews, advertisingBlock, menuGenres }) => {
  return <Home popularBooks={popularBooks} newBooks={newBooks} topAuthors={topAuthors} reviews={reviews} advertisingBlock={advertisingBlock} menuGenres={menuGenres} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const [popularRes, newRes, topAuthorsRes, reviewsRes, advertisingBlockRes, menuGenresRes] = await Promise.all([
    fetch(`${API_URL}satellite/books/popularity?page=1&page_size=25`),
    fetch(`${API_URL}satellite/books/news?page=1&page_size=12`),
    fetch(`${API_URL}satellite/authors/popular`),
    fetch(`${API_URL}satellite/reviews/last?count=${REVIEWS_FOR_SLIDER}`),
    fetch(`${API_URL}advertising_block`),
    fetch(`${MENU_API.genres}`),
  ]);
  const [popularBooks, newBooks, topAuthors, reviews, advertisingBlock, menuGenres] = await Promise.all([popularRes.json(), newRes.json(), topAuthorsRes.json(), reviewsRes.json(), advertisingBlockRes.json(), menuGenresRes.json()]);

  return { props: { popularBooks, newBooks, topAuthors, reviews, advertisingBlock, menuGenres }, revalidate: REVALIDATE_TIME };
};

export default HomePage;
