import { FC } from 'react';
import styles from './BookCardRating.module.scss';

interface IBookCardRating {
  rating: number | undefined;
  extraClassName?: string;
}

const BookCardRating: FC<IBookCardRating> = ({ rating, extraClassName }) => {
  const bookRating = Number(rating);

  if (bookRating > 0 && bookRating < 6) {
    const greyStars = 5 - bookRating;
    return (
      <div className={extraClassName ? `${styles.bookCardRating} ${styles[`${extraClassName}`]}` : `${styles.bookCardRating}`}>
        {Array.from(Array(bookRating), () => {
          return <span className={`${styles.bookCardRating__star} ${styles.bookCardRating__star_active}`} key={`${Math.random()}-orange`}></span>;
        })}
        {Array.from(Array(greyStars), () => {
          return <span className={`${styles.bookCardRating__star}`} key={`${Math.random()}-gray`}></span>;
        })}
        <span className={styles.bookCardRating__txt}>{bookRating}</span>
      </div>
    );
  } else {
    return (
      <div className={extraClassName ? `${styles.bookCardRating} ${styles[`${extraClassName}`]}` : `${styles.bookCardRating}`}>
        {Array.from(Array(5), () => {
          return <span className={styles.bookCardRating__star} key={`${Math.random()}-gray`}></span>;
        })}
        <span className={styles.bookCardRating__txt}>{bookRating}</span>
      </div>
    );
  }
};

export default BookCardRating;
